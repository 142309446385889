import getRoute from "~/utilities/configs/apiMap";
import { unitPageStore, type UpdatedUnitResponseDTO } from "~/store/unitPage";
import type { LandItem, PropertyItem } from "~/types/search-page-items";
import { gtagReachGoal } from "~/services/SEO/goals/createCustomGoal";
import { Api, type UnitResponseDTO } from "~/services/swagger/Api";

const apiClient = new Api();

export default defineNuxtRouteMiddleware(async (to) => {
  const { id } = to.params;

  if (!id) {
    return navigateTo("/404");
  }

  type Props = "Estate" | "Land" | "Other";

  const useUnitpageStore = unitPageStore();
  const currentType = (type: Props) => {
    switch (type) {
      case "Estate":
        return {
          component: "unit-page-estate-unit",
          process: useUnitpageStore.proccessUnitPageData,
        };
      case "Land":
        return {
          component: "unit-page-land-unit",
          process: useUnitpageStore.proccessUnitPageData,
        };
      default:
        return null;
    }
  };

  const checkProperty = async () => {
    const config = useRuntimeConfig();
    const baseUrl = config.public.baseURL as string;
    const setUrl =
      baseUrl + getRoute({ endpont: `get_unit`, id: id as string });
    try {
      const response = await useFetch(setUrl, {
        method: "HEAD",
        headers: {
          Authorization: `Bearer ${useCookie("acc_token").value}`,
        },
      });

      return (
        response.status.value === "success" || response.status.value === "ok"
      );
    } catch (error) {
      return false;
    }
  };
  try {
    const router = useRouter();
    const check = await checkProperty();
    if (!check) return navigateTo("/404");
    const unit = await apiClient.api.unitsControllerRetrieve(id as string);
    const type = currentType(unit.data.type);
    if (!type) return navigateTo("/404");
    type.process(unit.data as UpdatedUnitResponseDTO);
    useUnitpageStore.$patch({
      currentElementInstance: type.component,
    });
    gtagReachGoal.visit__unit({
      value: unit.data.name,
    });
    if (id === unit.data.id && unit.data.sanitizeName) {
      const localePath = useLocalePath();
      const newUrl = `/property/${unit.data.sanitizeName}`;
      router.push(localePath(newUrl))
    }
  } catch (error) {
    return navigateTo("/404");
  }
});
